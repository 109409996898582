import { useAuthModalContext, useUserId } from '@dreamstack/auth'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

export const useHandleAuthFlowForAction = () => {
  const userId = useUserId()
  const { setOpen, setActionHref, setActionCallback } = useAuthModalContext()
  const router = useRouter()
  const handleAuth = useCallback(
    (options: { actionHref?: string; actionCallback?: CallableFunction }) => {
      const { actionHref, actionCallback } = options
      if (userId) {
        actionCallback && actionCallback()
        actionHref && router.push(actionHref)
      } else {
        setOpen(true)
        setActionHref(actionHref)
        setActionCallback(actionCallback)
      }
    },
    [router, setActionCallback, setActionHref, setOpen, userId]
  )
  return handleAuth
}
