// ROOF_STOREY - Dachgeschoss
// LOFT - Loft
// MAISONETTE - Maisonette
// PENTHOUSE - Penthouse
// TERRACED_FLAT - Terrassenwohnung
// GROUND_FLOOR - Erdgeschosswohnung
// APARTMENT - Etagenwohnung
// RAISED_GROUND_FLOOR - Hochparterre
// HALF_BASEMENT - Souterrain
// OTHER - Sonstige
// SINGLE_FAMILY_HOUSE - Einfamilienhaus
// TWO_FAMILY_HOUSE - Zweifamilienhaus
// TERRACE_HOUSE - Reihenhaus
// MID_TERRACE_HOUSE - Reihenmittelhaus
// TERRACE_END_HOUSE - Reihenendhaus
// END_TERRACE_HOUSE - Reiheneckhaus
// MULTI_FAMILY_HOUSE - Mehrfamilienhaus
// TOWNHOUSE - Stadthaus
// FINCA - Finca
// FREEHOLD_FLAT - Eigentumswohnung
// BUNGALOW - Bungalow
// FARMHOUSE - Bauernhaus
// SEMIDETACHED_HOUSE - Doppelhaushälfte
// VILLA - Villa
// CASTLE_MANOR_HOUSE - Burg/Schloss
// SPECIAL_REAL_ESTATE - Besondere Immobilie
// TWIN_SINGLE_FAMILY_HOUSE - Doppeleinfamilienhaus
// GARAGE - Garage
// STREET_PARKING - Außenstellplatz
// CARPORT - Carport
// DUPLEX - Duplex
// CAR_PARK - Parkhaus
// UNDERGROUND_GARAGE - Tiefgarage
// DOUBLE_GARAGE - Doppelgarage
// NO_INFORMATION - Keine Angabe
// STUDIO - Atelier
// OFFICE - Büro
// OFFICE_FLOOR - Büroetage
// OFFICE_BUILDING - Bürohaus
// OFFICE_CENTRE - Bürozentrum
// OFFICE_STORAGE_BUILDING - Büro-/ Lagergebäude
// SURGERY - Praxis
// SURGERY_FLOOR - Praxisetage
// SURGERY_BUILDING - Praxishaus
// COMMERCIAL_CENTRE - Gewerbezentrum
// LIVING_AND_COMMERCIAL_BUILDING - Wohn- und Geschäftsgebäude
// OFFICE_AND_COMMERCIAL_BUILDING - Büro- und Geschäftsgebäude
// BAR_LOUNGE - Barbetrieb/Lounge
// CAFE - Café
// CLUB_DISCO - Club/Diskothek
// GUESTS_HOUSE - Gästehaus
// TAVERN - Gaststätte
// HOTEL - Hotel
// HOTEL_RESIDENCE - Hotelanwesen
// HOTEL_GARNI - Hotel garni
// PENSION - Pension
// RESTAURANT - Restaurant
// SHOWROOM_SPACE - Ausstellungsfläche
// SHOPPING_CENTRE - Einkaufszentrum
// FACTORY_OUTLET - Factory Outlet
// DEPARTMENT_STORE - Kaufhaus
// KIOSK - Kiosk
// STORE - Laden
// SELF_SERVICE_MARKET - SB-Markt
// SALES_AREA - Verkaufsfläche
// SALES_HALL - Verkaufshalle
// RESIDENCE - Anwesen
// FARM - Bauernhof
// HORSE_FARM - Reiterhof
// VINEYARD - Weingut
// REPAIR_SHOP - Werkstatt
// LEISURE_FACILITY - Freizeitanlage
// INDUSTRIAL_AREA - Gewerbefläche
// SPECIAL_ESTATE - Spezialobjekt
// LIVING_BUSINESS_HOUSE - Wohn- und Geschäftshaus
// HOUSING_ESTATE - Wohnanlage
// MICRO_APARTMENTS - Micro-Apartments
// COMMERCIAL_BUILDING - Geschäftshaus
// SHOP_SALES_FLOOR - Laden/Verkaufsfläche
// SUPERMARKET - Supermarkt
// RETAIL_PARK - Fachmarktzentrum
// BOARDING_HOUSE - Boarding House
// CLINIC - Klinik
// ASSISTED_LIVING - Betreutes Wohnen
// HALL_STORAGE - Halle/Logistik
// INDUSTRIAL_PROPERTY - Produktion/Fertigung

import { RsType } from './RsType.static'

export enum RsCategory {
  ROOF_STOREY = 'ROOF_STOREY',
  LOFT = 'LOFT',
  OFFICE_LOFT = 'OFFICE_LOFT',
  MAISONETTE = 'MAISONETTE',
  PENTHOUSE = 'PENTHOUSE',
  TERRACED_FLAT = 'TERRACED_FLAT',
  GROUND_FLOOR = 'GROUND_FLOOR',
  APARTMENT = 'APARTMENT',
  RAISED_GROUND_FLOOR = 'RAISED_GROUND_FLOOR',
  HALF_BASEMENT = 'HALF_BASEMENT',
  ATTIKA = 'ATTIKA',
  OTHER = 'OTHER',
  SINGLE_FAMILY_HOUSE = 'SINGLE_FAMILY_HOUSE',
  TWO_FAMILY_HOUSE = 'TWO_FAMILY_HOUSE',
  TERRACE_HOUSE = 'TERRACE_HOUSE',
  MID_TERRACE_HOUSE = 'MID_TERRACE_HOUSE',
  TERRACE_END_HOUSE = 'TERRACE_END_HOUSE',
  END_TERRACE_HOUSE = 'END_TERRACE_HOUSE',
  MULTI_FAMILY_HOUSE = 'MULTI_FAMILY_HOUSE',
  TOWNHOUSE = 'TOWNHOUSE',
  FINCA = 'FINCA',
  FREEHOLD_FLAT = 'FREEHOLD_FLAT',
  BUNGALOW = 'BUNGALOW',
  SEMIDETACHED_HOUSE = 'SEMIDETACHED_HOUSE',
  VILLA = 'VILLA',
  CASTLE_MANOR_HOUSE = 'CASTLE_MANOR_HOUSE',
  SPECIAL_REAL_ESTATE = 'SPECIAL_REAL_ESTATE',
  TWIN_SINGLE_FAMILY_HOUSE = 'TWIN_SINGLE_FAMILY_HOUSE',
  GARAGE = 'GARAGE',
  STREET_PARKING = 'STREET_PARKING',
  CARPORT = 'CARPORT',
  DUPLEX = 'DUPLEX',
  CAR_PARK = 'CAR_PARK',
  UNDERGROUND_GARAGE = 'UNDERGROUND_GARAGE',
  DOUBLE_GARAGE = 'DOUBLE_GARAGE',
  NO_INFORMATION = 'NO_INFORMATION',
  STUDIO = 'STUDIO',
  OFFICE = 'OFFICE',
  OFFICE_FLOOR = 'OFFICE_FLOOR',
  OFFICE_BUILDING = 'OFFICE_BUILDING',
  OFFICE_CENTRE = 'OFFICE_CENTRE',
  OFFICE_STORAGE_BUILDING = 'OFFICE_STORAGE_BUILDING',
  SURGERY = 'SURGERY',
  SURGERY_FLOOR = 'SURGERY_FLOOR',
  SURGERY_BUILDING = 'SURGERY_BUILDING',
  COMMERCIAL_CENTRE = 'COMMERCIAL_CENTRE',
  LIVING_AND_COMMERCIAL_BUILDING = 'LIVING_AND_COMMERCIAL_BUILDING',
  OFFICE_AND_COMMERCIAL_BUILDING = 'OFFICE_AND_COMMERCIAL_BUILDING',
  BAR_LOUNGE = 'BAR_LOUNGE',
  CAFE = 'CAFE',
  CLUB_DISCO = 'CLUB_DISCO',
  GUESTS_HOUSE = 'GUESTS_HOUSE',
  TAVERN = 'TAVERN',
  HOTEL = 'HOTEL',
  HOTEL_RESIDENCE = 'HOTEL_RESIDENCE',
  HOTEL_GARNI = 'HOTEL_GARNI',
  PENSION = 'PENSION',
  RESTAURANT = 'RESTAURANT',
  SHOWROOM_SPACE = 'SHOWROOM_SPACE',
  SHOPPING_CENTRE = 'SHOPPING_CENTRE',
  FACTORY_OUTLET = 'FACTORY_OUTLET',
  DEPARTMENT_STORE = 'DEPARTMENT_STORE',
  KIOSK = 'KIOSK',
  STORE = 'STORE',
  SELF_SERVICE_MARKET = 'SELF_SERVICE_MARKET',
  SALES_AREA = 'SALES_AREA',
  SALES_HALL = 'SALES_HALL',
  RESIDENCE = 'RESIDENCE',
  FARM = 'FARM',
  HORSE_FARM = 'HORSE_FARM',
  VINEYARD = 'VINEYARD',
  REPAIR_SHOP = 'REPAIR_SHOP',
  LEISURE_FACILITY = 'LEISURE_FACILITY',
  INDUSTRIAL_AREA = 'INDUSTRIAL_AREA',
  SPECIAL_ESTATE = 'SPECIAL_ESTATE',
  LIVING_BUSINESS_HOUSE = 'LIVING_BUSINESS_HOUSE',
  HOUSING_ESTATE = 'HOUSING_ESTATE',
  MICRO_APARTMENTS = 'MICRO_APARTMENTS',
  COMMERCIAL_BUILDING = 'COMMERCIAL_BUILDING',
  SHOP_SALES_FLOOR = 'SHOP_SALES_FLOOR',
  SUPERMARKET = 'SUPERMARKET',
  RETAIL_PARK = 'RETAIL_PARK',
  BOARDING_HOUSE = 'BOARDING_HOUSE',
  CLINIC = 'CLINIC',
  ASSISTED_LIVING = 'ASSISTED_LIVING',
  HALL_STORAGE = 'HALL_STORAGE',
  INDUSTRIAL_PROPERTY = 'INDUSTRIAL_PROPERTY',
  REHAB_CLINIC = 'REHAB_CLINIC',
  MEDICAL_SERVICE_CENTER = 'MEDICAL_SERVICE_CENTER',
  INTEGRATION_ASSISTANCE = 'INTEGRATION_ASSISTANCE',
  DAY_NURSERY = 'DAY_NURSERY',
  DAY_CARE = 'DAY_CARE',
  NURSING_HOME = 'NURSING_HOME',
  PLOT = 'PLOT',
  INVEST_FREEHOLD_FLAT = 'INVEST_FREEHOLD_FLAT',
  INVEST_SINGLE_FAMILY_HOUSE = 'INVEST_SINGLE_FAMILY_HOUSE',
  INVEST_MULTI_FAMILY_HOUSE = 'INVEST_MULTI_FAMILY_HOUSE',
  INVEST_LIVING_BUSINESS_HOUSE = 'INVEST_LIVING_BUSINESS_HOUSE',
  INVEST_HOUSING_ESTATE = 'INVEST_HOUSING_ESTATE',
  INVEST_MICRO_APARTMENTS = 'INVEST_MICRO_APARTMENTS',
  INVEST_OFFICE_BUILDING = 'INVEST_OFFICE_BUILDING',
  INVEST_COMMERCIAL_BUILDING = 'INVEST_COMMERCIAL_BUILDING',
  INVEST_OFFICE_AND_COMMERCIAL_BUILDING = 'INVEST_OFFICE_AND_COMMERCIAL_BUILDING',
  INVEST_SHOP_SALES_FLOOR = 'INVEST_SHOP_SALES_FLOOR',
  INVEST_SUPERMARKET = 'INVEST_SUPERMARKET',
  INVEST_SHOPPING_CENTRE = 'INVEST_SHOPPING_CENTRE',
  INVEST_RETAIL_PARK = 'INVEST_RETAIL_PARK',
  INVEST_HOTEL = 'INVEST_HOTEL',
  INVEST_BOARDING_HOUSE = 'INVEST_BOARDING_HOUSE',
  INVEST_SURGERY_BUILDING = 'INVEST_SURGERY_BUILDING',
  INVEST_CLINIC = 'INVEST_CLINIC',
  INVEST_REHAB_CLINIC = 'INVEST_REHAB_CLINIC',
  INVEST_MEDICAL_SERVICE_CENTER = 'INVEST_MEDICAL_SERVICE_CENTER',
  INVEST_INTEGRATION_ASSISTANCE = 'INVEST_INTEGRATION_ASSISTANCE',
  INVEST_DAY_NURSERY = 'INVEST_DAY_NURSERY',
  INVEST_DAY_CARE = 'INVEST_DAY_CARE',
  INVEST_NURSING_HOME = 'INVEST_NURSING_HOME',
  INVEST_ASSISTED_LIVING = 'INVEST_ASSISTED_LIVING',
  INVEST_COMMERCIAL_CENTRE = 'INVEST_COMMERCIAL_CENTRE',
  INVEST_HALL_STORAGE = 'INVEST_HALL_STORAGE',
  INVEST_INDUSTRIAL_PROPERTY = 'INVEST_INDUSTRIAL_PROPERTY',
  INVEST_CAR_PARK = 'INVEST_CAR_PARK',
  INVEST_PLOT = 'INVEST_PLOT',
  INVEST_OTHER = 'INVEST_OTHER',
}

export const RsCategoriesForRsTypes: Record<RsType[number], RsCategory[]> = {
  //WOHNUNG
  [RsType.APARTMENT]: [
    RsCategory.ROOF_STOREY,
    RsCategory.LOFT,
    RsCategory.MAISONETTE,
    RsCategory.PENTHOUSE,
    RsCategory.TERRACED_FLAT,
    RsCategory.GROUND_FLOOR,
    RsCategory.APARTMENT,
    RsCategory.RAISED_GROUND_FLOOR,
    RsCategory.HALF_BASEMENT,
    RsCategory.ATTIKA,
    RsCategory.OTHER,
  ],
  //[RsType.GARAGE]:[],
  [RsType.INVESTMENT]: [
    RsCategory.INVEST_FREEHOLD_FLAT,
    RsCategory.INVEST_SINGLE_FAMILY_HOUSE,
    RsCategory.INVEST_MULTI_FAMILY_HOUSE,
    RsCategory.INVEST_LIVING_BUSINESS_HOUSE,
    RsCategory.INVEST_HOUSING_ESTATE,
    RsCategory.INVEST_MICRO_APARTMENTS,
    RsCategory.INVEST_OFFICE_BUILDING,
    RsCategory.INVEST_COMMERCIAL_BUILDING,
    RsCategory.INVEST_OFFICE_AND_COMMERCIAL_BUILDING,
    RsCategory.INVEST_SHOP_SALES_FLOOR,
    RsCategory.INVEST_SUPERMARKET,
    RsCategory.INVEST_SHOPPING_CENTRE,
    RsCategory.INVEST_RETAIL_PARK,
    RsCategory.INVEST_HOTEL,
    RsCategory.INVEST_BOARDING_HOUSE,
    RsCategory.INVEST_SURGERY_BUILDING,
    RsCategory.INVEST_CLINIC,
    RsCategory.INVEST_REHAB_CLINIC,
    RsCategory.INVEST_MEDICAL_SERVICE_CENTER,
    RsCategory.INVEST_INTEGRATION_ASSISTANCE,
    RsCategory.INVEST_DAY_NURSERY,
    RsCategory.INVEST_DAY_CARE,
    RsCategory.INVEST_NURSING_HOME,
    RsCategory.INVEST_ASSISTED_LIVING,
    RsCategory.INVEST_COMMERCIAL_CENTRE,
    RsCategory.INVEST_HALL_STORAGE,
    RsCategory.INVEST_INDUSTRIAL_PROPERTY,
    RsCategory.INVEST_CAR_PARK,
    RsCategory.INVEST_PLOT,
    RsCategory.INVEST_OTHER,
  ],
  [RsType.OFFICE]: [
    RsCategory.OFFICE_LOFT,
    RsCategory.STUDIO,
    RsCategory.OFFICE,
    RsCategory.OFFICE_FLOOR,
    RsCategory.OFFICE_BUILDING,
    RsCategory.OFFICE_CENTRE,
    RsCategory.OFFICE_STORAGE_BUILDING,
    RsCategory.SURGERY,
    RsCategory.SURGERY_FLOOR,
    RsCategory.SURGERY_BUILDING,
    RsCategory.COMMERCIAL_CENTRE,
    RsCategory.LIVING_AND_COMMERCIAL_BUILDING,
    RsCategory.OFFICE_AND_COMMERCIAL_BUILDING,
  ],
  //Einzelhandel
  [RsType.STORE]: [
    RsCategory.SHOWROOM_SPACE,
    RsCategory.SHOPPING_CENTRE,
    RsCategory.FACTORY_OUTLET,
    RsCategory.DEPARTMENT_STORE,
    RsCategory.KIOSK,
    RsCategory.STORE,
    RsCategory.SELF_SERVICE_MARKET,
    RsCategory.SALES_AREA,
    RsCategory.SALES_HALL,
  ],
  //[RsType.HOUSE]:[],
  //[RsType.GASTRONOMY]:[],
  //[RsType.SPECIAL_PURPOSE]:[],
  //[RsType.TRADE_SITE]:[],
}
