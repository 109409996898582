import type { FunctionComponent } from 'react'
import 'twin.macro'

export const PageBlockSpacingContainer: FunctionComponent<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  return (
    <div tw="space-y-px-64 mt-px-64 ml:(space-y-px-96 mt-px-96) lg:(space-y-px-96 mt-px-96)">
      {children}
    </div>
  )
}
