import type { PostSavedQuery } from '@dreamstack/propstack-api'
import {
  NewBuildingProp,
  OldBuildingProp,
  PropStackKeyOfOldNewBuildingProp,
  RsCategoriesForRsTypes,
} from '@dreamstack/real-estate-logic'
import includes from 'lodash/includes'
import map from 'lodash/map'
import type { PropertySearchFilterValues } from '..'
import { MaxFilterValue } from '..'
import { RsCategoryFilterKeyMapping } from './useGetPropstackSavedQueryFromFilter'

export const getPropstackSavedQueryFromFilter = ({
  filter,
}: {
  filter: PropertySearchFilterValues
}): PostSavedQuery => {
  const result: PostSavedQuery = {}
  // Price

  result.price = filter.priceRange[0]
  result.price_to =
    filter.priceRange[1] < MaxFilterValue.price
      ? filter.priceRange[1]
      : undefined

  // Cities
  result.cities = filter.cities

  // Regions
  result.regions = filter.regions

  // Locations - will later be mapped into ids for propstack
  result.locations = filter.locations

  // RoomRange
  result.number_of_rooms = filter.roomRange[0]
  result.number_of_rooms_to =
    filter.roomRange[1] < MaxFilterValue.roomRange
      ? filter.roomRange[1]
      : undefined

  // LivingSpace
  result.living_space = filter.livingspace[0]
  result.living_space_to =
    filter.livingspace[1] < MaxFilterValue.livingSpace
      ? filter.livingspace[1]
      : undefined

  // TypeOfImmo
  const transformedRsType =
    filter.typeOfImmo !== 'all' ? filter.typeOfImmo.toUpperCase() : null

  if (transformedRsType) {
    if (transformedRsType === 'APARTMENT' && filter.rsCategories.length) {
      result.rs_type_categories = map(filter.rsCategories, (c) => [
        transformedRsType,
        RsCategoryFilterKeyMapping[c],
      ])
    } else {
      result.rs_type_categories = map(
        RsCategoriesForRsTypes[transformedRsType],
        (c) => [transformedRsType, c]
      )
    }
  }

  // Rented
  result.rented =
    filter.rented === 'rented'
      ? 'true'
      : filter.rented === 'not-rented'
      ? 'false'
      : undefined

  result.lift = includes(filter.otherFilters, 'lift') ? 'true' : null

  result.balcony = includes(filter.otherFilters, 'balconyTerrace')
    ? 'true'
    : null

  const oldBuildingSelected = filter.constructionAgeType === 'oldbuilding'
  const newBuildingSelected = filter.constructionAgeType === 'newbuilding'
  const buildingFilterValues = oldBuildingSelected
    ? OldBuildingProp
    : newBuildingSelected
    ? NewBuildingProp
    : []

  //TODO: Currently ignored by the API
  result.custom_fields = {
    [PropStackKeyOfOldNewBuildingProp]: [...buildingFilterValues],
    wassernaehe: includes(filter.otherFilters, 'waterClose') ? true : null,
  }
  result.marketing_type = 'BUY'

  return result
}
