import {
  fetchStaticPageData,
  StaticPage,
} from '@dreamstack/accentro-contentful'
import {
  OnboardingContextProvider,
  SelfManagedOnboardingModal,
} from '@dreamstack/feature-components'
import { defaultQueries } from '../lib/defaultQueries'
import { getStaticPropsPlus } from '../lib/getStaticPropsPlus'

export default function Home() {
  return (
    <>
      <OnboardingContextProvider>
        <SelfManagedOnboardingModal />
      </OnboardingContextProvider>
      <StaticPage slug={'index'} />
    </>
  )
}

export const getStaticProps = getStaticPropsPlus({
  queries: defaultQueries,
  getProps: async ({ apolloClient }) => {
    await fetchStaticPageData({ apolloClient, slug: 'index' })
  },
})
