import filter from 'lodash/filter'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

export enum RsType {
  APARTMENT = 'APARTMENT',
  GARAGE = 'GARAGE',
  INVESTMENT = 'INVESTMENT',
  OFFICE = 'OFFICE',
  STORE = 'STORE',
  HOUSE = 'HOUSE',
  GASTRONOMY = 'GASTRONOMY',
  SPECIAL_PURPOSE = 'SPECIAL_PURPOSE',
  TRADE_SITE = 'TRADE_SITE',
}

export type RsTypeDetails = {
  rsType: RsType
  showAsSearchFilter: boolean
  excludeFromAllQueries: boolean
}

export const rsTypeDetails: RsTypeDetails[] = [
  {
    rsType: RsType.APARTMENT,
    showAsSearchFilter: true,
    excludeFromAllQueries: false,
  },
  {
    rsType: RsType.GARAGE,
    showAsSearchFilter: false,
    excludeFromAllQueries: true,
  },
  {
    rsType: RsType.INVESTMENT,
    showAsSearchFilter: false,
    excludeFromAllQueries: false,
  },
  {
    rsType: RsType.OFFICE,
    showAsSearchFilter: true,
    excludeFromAllQueries: false,
  },
  {
    rsType: RsType.STORE,
    showAsSearchFilter: true,
    excludeFromAllQueries: false,
  },
  {
    rsType: RsType.HOUSE,
    showAsSearchFilter: false,
    excludeFromAllQueries: false,
  },
  {
    rsType: RsType.GASTRONOMY,
    showAsSearchFilter: false,
    excludeFromAllQueries: false,
  },
  {
    rsType: RsType.SPECIAL_PURPOSE,
    showAsSearchFilter: false,
    excludeFromAllQueries: true,
  },
  {
    rsType: RsType.TRADE_SITE,
    showAsSearchFilter: false,
    excludeFromAllQueries: false,
  },
]

const detailsByRsType = keyBy(rsTypeDetails, ({ rsType }) => rsType)

export const getRsTypeDetails = (rsType: RsType | string) => {
  const details = detailsByRsType[rsType]
  if (!details) {
    throw new Error(`Unknown RsType "${rsType}"`)
  }
  return details
}

export const EXCLUDED_RS_TYPES = map(
  filter(rsTypeDetails, (t) => t.excludeFromAllQueries),
  (t) => t.rsType
)
