import type { ApolloClient } from '@apollo/client'
import { ContentfulBlocks } from '@dreamstack/accentro-contentful'
import type { HeroProps } from '@dreamstack/feature-components'
import { MainLayout, useParams } from '@dreamstack/feature-components'
import type {
  IOtherLocaleUrlsContextIncomplete,
  LocaleString,
} from '@dreamstack/i18n'
import { OtherLocaleUrlsContextProvider, useLocale } from '@dreamstack/i18n'
import { queryGetStaticPagesBySlug } from '@dreamstack/investors-graphql'
import { DreamstackSeo } from '@dreamstack/seo'
import { AdditionalTrackingInfoContextProvider } from '@dreamstack/tracking'
import { trpc } from '@dreamstack/trpc/client'
import first from 'lodash/first'
import type { FunctionComponent } from 'react'
import { useMemo } from 'react'
import 'twin.macro'
import { PageBlockSpacingContainer } from '../contentful/PageBlockSpacingContainer'

export const StaticPage: FunctionComponent<
  React.PropsWithChildren<{
    slug: string
    className?: string
  }>
> = ({ slug, className, children }) => {
  const locale = useLocale()
  const { data: trpcData } = trpc.cms.getStaticPage.useQuery(
    { slug, locale },
    {
      trpc: { ssr: true },
      staleTime: Infinity,
    }
  )

  // PropertyId from Params for Analytics
  const { propertyId: propertyIdString } = useParams()
  const propertyId = propertyIdString ? parseInt(propertyIdString) : undefined

  const page = trpcData?.page

  const additionalInfoMemoized = useMemo(
    () => ({
      slug,
      staticPageId: page?.sys.id,
      propertyId,
      title: page?.pageTitle,
    }),
    [page?.pageTitle, page?.sys.id, propertyId, slug]
  )

  if (!page) {
    return <>404: Page with slug {slug} not found</>
  }

  const blocks = trpcData.blocksData
  const hero = page.hero
  const pageTag = page.pageTag

  let otherLocaleUrls: IOtherLocaleUrlsContextIncomplete = {
    de: page.slugDe ? { url: page.slugDe } : null,
    en: page.slugEn ? { url: page.slugEn } : null,
  }

  const isIndex = slug === 'index'
  if (isIndex) {
    otherLocaleUrls = {
      de: { url: '/' },
      en: { url: '/' },
    }
  }
  const title = isIndex ? undefined : page.seo?.title || page.pageTitle

  return (
    <OtherLocaleUrlsContextProvider context={otherLocaleUrls}>
      <AdditionalTrackingInfoContextProvider context={additionalInfoMemoized}>
        <DreamstackSeo
          title={title ?? undefined}
          description={page.seo?.description ?? undefined}
          nofollow={page.seo?.noFollowNoIndex ?? false}
          noindex={page.seo?.noFollowNoIndex ?? false}
        />
        <MainLayout
          removePaddingBottom={page.removePaddingBottom}
          pageTag={pageTag}
          showContactFooter={page.showContactFooter}
          showNewsletterFooter={page.showNewsletterFooter}
          standalone={page.standalone}
          navbarProps={{ forceDarkTextColor: !hero }}
          hero={hero ? ({ ...hero, noMarginBottom: true } as HeroProps) : null}
          className={className}
        >
          <PageBlockSpacingContainer>
            {children}
            <ContentfulBlocks
              customProps={{ newsType: page.newsType, region: page.region }}
              blocks={blocks}
            />
          </PageBlockSpacingContainer>
        </MainLayout>
      </AdditionalTrackingInfoContextProvider>
    </OtherLocaleUrlsContextProvider>
  )
}

export const fetchStaticPageData = async ({
  apolloClient,
  slug,
  locale,
}: {
  apolloClient: ApolloClient<any>
  slug: string
  locale?: LocaleString
}) => {
  const { data } = await queryGetStaticPagesBySlug({
    apolloClient,
    variables: { slug },
    fetchPolicy: 'no-cache', // no need to cache this for client
  })
  const page = first(data?.pages?.items)
  return page
}
