import type { PostSavedQuery } from '@dreamstack/propstack-api'
import { useCallback } from 'react'
import type {
  PropertySearchFilterValues,
  RsCategory,
} from '../next/PropertySearchFilter'
import { getPropstackSavedQueryFromFilter } from './getPropstackSavedQueryFromFilter'

export const RsCategoryFilterKeyMapping: { [key in RsCategory]: string } = {
  raisedGroundFloor: 'RAISED_GROUND_FLOOR',
  groundFloor: 'GROUND_FLOOR',
  maisonette: 'MAISONETTE',
  roofStorey: 'ROOF_STOREY',
  apartment: 'APARTMENT',
}

export const useGetPropstackSavedQueryFromFilter = () =>
  useCallback(
    ({ filter }: { filter: PropertySearchFilterValues }): PostSavedQuery => {
      return getPropstackSavedQueryFromFilter({ filter })
    },
    []
  )
